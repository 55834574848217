.main {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 8px;
  position: relative;
  padding: 16px;

  .title {
    color: rgba(0, 0, 0, 0.45);
  }

  .buttonsNumberInput {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

@primary-color: #355CA7;