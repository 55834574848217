@import '../../../assets/styles/variables';

.wrapper {
  margin-bottom: 24px;

  .input {
    display: flex;
    height: 70px;
    color: #000000; // из-за того что Input disabled=true
    cursor: pointer; // из-за того что Input disabled=true
    background-color: #ffffff;
  }
}
.spin {
  font-size: 24px;
  color: @primary-color;
}

@primary-color: #355CA7;