.main {
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 20px;

  .link {
    color: #000000;
    text-decoration: underline;
  }
}

@primary-color: #355CA7;