/* Theme Big Rating */

@radius: 15px;

.card,
.card:hover {
  color: #000000;
  border-radius: @radius;
  box-shadow: 0 3px 24px rgba(219, 219, 219, 0.5);

  .photo {
    width: 100%;
    height: 180px;

    :global {
      .ant-image {
        width: 100%;
      }
    }

    img,
    .skeletonPhoto {
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-radius: @radius @radius 0 0;
    }
  }

  .info {
    padding: 20px;

    .name {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 2px;
    }

    .location {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #828282;
      margin: 0 0 10px 0;
      padding: 0;
      display: flex;

      .item {
        padding-right: 25px;
        margin: 0;
        line-height: 22px;

        &:first-child {
          list-style: none;
        }

        &::marker {
          font-size: 1em;
        }
      }
    }

    .specifications {
      margin-bottom: 4px;
      justify-content: left;
    }

    .rating {
      .icon {
        margin-right: 4px;
      }
    }
  }

  .skeletonInfo {
    height: 190px;
    padding: 20px;

    .skeletonName {
      height: 32px;
      margin-bottom: 8px;
    }

    .skeletonText {
      height: 22px;
      margin-bottom: 8px;
    }
  }
}

@primary-color: #355CA7;