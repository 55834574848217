@import './../../assets/styles/variables';

.footer {
  display: flex;
  flex-flow: row wrap;
  row-gap: 16px;
  column-gap: 16px;
  padding: 0 16px;
  color: #757575;
  font-size: 14px;

  .title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
  }

  .collapse {
    width: 100%;
    margin-bottom: 16px;
  }

  .mobileApps,
  .socialNetworks,
  .copyright {
    padding: 0;
  }

  .mobileApps,
  .socialNetworks {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
}

@media screen and (min-width: @screen-md-min) {
  .footer {
    font-size: 16px;

    .copyright {
      font-size: 14px;
    }

    .mobileApps,
    .socialNetworks {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .footer {
    .contacts {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .services,
    .mobileApps {
      flex: 0 0 48%;
      max-width: 48%;
    }

    .sitemaps,
    .socialNetworks {
      flex: 0 0 48%;
      max-width: 48%;
    }

    .copyright {
      flex: 0 0 100%;
      max-width: 100%;
      order: 3;
    }
  }
}

@media screen and (min-width: @screen-lg-min) {
  .footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    row-gap: 16px;

    .contacts,
    .copyright {
      flex: 0 0 45%;
      max-width: 45%;
    }

    .services,
    .mobileApps {
      flex: 0 0 18%;
      max-width: 18%;
    }

    .sitemaps,
    .socialNetworks {
      flex: 0 0 18%;
      max-width: 18%;
    }
  }
}

@primary-color: #355CA7;