.main {
  display: flex;
  flex-flow: row;

  .item {
    display: block;
    margin-right: 16px;
    height: 22px;

    svg {
      height: 22px;
    }
  }
}

@primary-color: #355CA7;