@import './../../../../assets/styles/variables';

.selectorLink {
  display: flex;
  align-items: center;
  color: #000000;
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    color: @primary-color;
  }

  .flag {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid #cccccc;
    margin-right: 8px;
  }
}

@primary-color: #355CA7;