@import './../../../../assets/styles/variables';

.main {
  padding: 17px 20px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
}

.head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 26px;

  .title {
    font-weight: 600;
    font-size: 18px;
  }

  .data {
    font-size: 12px;
    color: #a5a3a3;
  }
}

.body {
  display: flex;

  .avatar {
    padding-right: 22px;
  }

  .contacts {
    .name {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 6px;
    }

    .phone {
    }

    .email {
    }
  }
}

@media screen and (min-width: @screen-lg-min) {
  .main {
    padding: 24px 28px;
  }
}

@primary-color: #355CA7;