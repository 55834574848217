@import './../../assets/styles/variables';

@thumbnailWidth: 100px;
@thumbnailHeight: 80px;
@thumbnailBorderDesktop: 4px;
@thumbnailBorderMobile: 3px;
@aspectRatio: 680/490;

.main {
  :global {
    // навигация
    .image-gallery-svg {
      max-width: 30px;
    }
  }
}

.thumbnail {
  width: @thumbnailWidth + (@thumbnailBorderMobile * 2);
  height: @thumbnailHeight + (@thumbnailBorderMobile * 2);

  :global {
    .image-gallery-thumbnail-inner,
    .image-gallery-thumbnail-image {
      width: @thumbnailWidth;
      height: @thumbnailHeight;
      object-fit: cover;
    }
  }
}

.original {
  :global {
    .image-gallery-image {
      aspect-ratio: @aspectRatio;
    }
  }
}

.skeletonMain {
  margin-bottom: 40px + 6px;

  .skeletonPreview {
    aspect-ratio: @aspectRatio;
    width: 100%;
  }
  .skeletonThumbnails {
    display: flex;
    margin: 6px 0;
    height: @thumbnailHeight;
    width: 100%;
  }
}

@media screen and (min-width: @screen-md-min) {
  .thumbnail {
    width: @thumbnailWidth + (@thumbnailBorderDesktop * 2);
    height: @thumbnailHeight + (@thumbnailBorderDesktop * 2);
  }

  .skeletonMain {
    margin-bottom: 40px + 9px;

    .skeletonThumbnails {
      margin: 9px 0;
    }
  }
}

@primary-color: #355CA7;