@import '../../../../assets/styles/variables';

.list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      &:after {
        content: '';
        border-right: 1px #d8d8d8 solid;
        right: 0;
        height: 12px;
        width: 1px;
        //padding: 0 6px;
        margin: 0 8px 0 8px;
      }
    }

    .icon {
      //max-height: 22px;
      margin-right: 4px;
    }
  }
}

.skeleton {
  width: 100%;
  height: 22px;
}

@primary-color: #355CA7;