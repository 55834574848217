@import '../../assets/styles/variables';

.themeUnderlining.main {
  .list {
    display: flex;

    .item {
      padding: 10px 20px 15px 20px;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      user-select: none;
      cursor: pointer;
    }

    .selected {
      border-bottom: 2px solid @primary-color;
    }

    .selected {
      font-weight: 700;
    }
  }
}

.themeUnderlining.main .list:hover > .selected:not(:hover) {
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  background: unset;
}

@media screen and (min-width: @screen-lg-min) {
  .themeUnderlining.main {
    .list {
      .item {
        padding-left: 24px;
        padding-right: 24px;
      }

      .item:hover {
        border-bottom: 2px solid @primary-color;
      }
    }
  }
}

.themeButton.main {
  @heightButton: 46px;
  @paddingList: 5px;

  box-shadow: 0 3px 24px 0 #dbdbdb80;
  border-radius: (((@paddingList * 2) + @heightButton) / 2);
  padding: @paddingList;

  .list {
    display: flex;

    .item {
      display: flex;
      align-items: center;
      height: @heightButton;
      padding: 0 24px;
      border-radius: (@heightButton / 2);
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      text-align: center;
      color: #828282;
      cursor: pointer;
    }

    .item:hover,
    .selected {
      background: @primary-color;
      color: #ffffff;
    }
  }
}

.themeButton.main .list:hover > .selected:not(:hover) {
  color: #828282;
  background: unset;
}

@primary-color: #355CA7;