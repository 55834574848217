@import '../../../../assets/styles/variables';

.main {
  margin-right: 110px;
  animation-name: unset;
  animation-duration: unset;
  animation-timing-function: unset;
  animation-fill-mode: unset;
}

@media screen and (max-width: @screen-xs-max) {
  .main {
    width: calc(100% - 140px);
  }
}

@primary-color: #355CA7;