.item {
  padding: 17px 20px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  margin-bottom: 16px;

  .fullName {
    font-weight: bold;
  }
}

@primary-color: #355CA7;