.main {
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
    height: 13px;
  }
}

@primary-color: #355CA7;