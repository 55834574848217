.breadcrumb {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  h1 {
    display: inline;
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
    font-size: 14px;
  }
}

.skeleton {
  height: 22px;
  max-width: 200px;
}

@primary-color: #355CA7;