@import './../../assets/styles/variables';

.main {
  display: flex;
  align-items: center;
}

.titlePage {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.blockTransitionSubscription {
  width: 100%;
  text-align: right;
  margin-bottom: 16px;
}

.selectType {
  margin-bottom: 30px;
}

.selectTime {
  width: max-content;
  margin-bottom: 30px;
}

.rates {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 24px;
  width: 100%;
  margin-bottom: 40px;

  .rate {
    width: 100%;
    max-width: 400px;
  }
}

.faq {
  width: 100%;
  margin-bottom: 40px;
}

.contact {
  margin-bottom: 100px;
}

@media screen and (min-width: @screen-lg-min) {
  .titlePage {
    margin-top: 75px;
    margin-bottom: 42px;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  .selectType {
    margin-bottom: 42px;
  }

  .selectTime {
    margin-bottom: 42px;
  }

  .rates {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    column-gap: 46px;
    margin-bottom: 100px;
  }

  .faq {
    margin-bottom: 60px;
  }

  .contact {
    margin-bottom: 100px;
  }
}

@primary-color: #355CA7;