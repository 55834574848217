@import '../../assets/styles/variables';

.container {
  display: flex;
}

.breadcrumb {
  margin: (@main-gap / 2) 0;
}

.main {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin: (@main-gap / 2) 0;
}

.left {
  width: 100%;
  margin-bottom: 40px;

  .photos {
    margin-bottom: 40px;
  }

  .icons {
    max-width: 260px;
    margin-bottom: 15px;
  }

  .title {
    margin-bottom: 15px;
  }

  .rating {
    margin-bottom: 6px;
  }

  .location {
    margin-bottom: 15px;
  }

  .description {
    margin-bottom: 25px;
  }
}

.right {
  width: 100%;

  .price {
    margin-bottom: 30px;
  }

  :global {
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: 12px;

      &:after {
        left: 33%;
      }
    }
    .ant-checkbox-checked {
      &:after {
        border-radius: 12px;
      }
    }
  }
}

@media screen and (min-width: @screen-xl-min) {
  .main {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }

  .left {
    max-width: 680px;
    margin-bottom: 0;
  }

  .right {
    max-width: 440px;
    padding: 40px;
    border: 1px solid #d0d0d0;
    border-radius: 15px;
  }
}

@primary-color: #355CA7;