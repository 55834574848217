@import '../../assets/styles/variables';

.list {
  display: flex;

  &.small .icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  &.large .icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
}

.icon {
  width: 16px;
  height: 16px;
  fill: @primary-color;
  margin-right: 6px;
}

.themeShadow {
  .icon {
    fill: #ffcd1a;
  }
  .shadow {
    fill: #e2e2e2;
  }
}

@primary-color: #355CA7;