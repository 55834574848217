@import '../../../../assets/styles/variables';

@cardHeight: 508px;
@cardWidth: 280px;
@cardWidthDesktop: 380px;
@contentPadding: 24px;
@shadowWidth: 24px;
@radius: 15px;

.swiper {
  padding: 0 @container-padding !important;

  .slide {
    width: @cardWidth;
    min-height: @cardHeight + @shadowWidth;
    height: 100%;
    cursor: pointer;
    align-items: flex-start;
  }

  .card {
    display: flex;
    flex-wrap: wrap;
    width: @cardWidth;
    min-height: @cardHeight;
    height: 100%;
    border-radius: @radius;
    box-shadow: 0 3px @shadowWidth rgba(219, 219, 219, 0.3);
    color: #000000;

    .photo {
      width: @cardWidth;
      height: 240px;
      border-radius: @radius @radius 0 0;

      img {
        width: @cardWidth;
        height: 240px;
        border-radius: @radius @radius 0 0;
      }
    }

    .content {
      padding: @contentPadding;

      .title {
        width: @cardWidth - (@contentPadding * 2);
        margin-bottom: 24px;
        font-size: 22px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .short {
        height: 88px;
        margin-bottom: 16px;
      }

      .date {
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
        color: #828282;
      }
    }
  }
}

@media screen and (min-width: @screen-md-min) {
  .swiper {
    .slide {
      width: @cardWidthDesktop;
    }

    .card {
      width: @cardWidthDesktop;

      .photo {
        width: @cardWidthDesktop;
        height: 240px;

        img {
          width: @cardWidthDesktop;
          height: 240px;
          border-radius: @radius @radius 0 0;
        }
      }
      .content {
        .title {
          width: @cardWidthDesktop - (@contentPadding * 2);
        }
      }
    }
  }
}

@primary-color: #355CA7;