@import '../../assets/styles/variables';

.boatLength {
  width: 29px;
  height: 14px;
}

.persons {
  width: 19px;
  height: 17px;
}

.cabins {
  width: 15px;
  height: 20px;
}

.capitan {
  width: 18px;
  height: 21px;
}

.boatLengthV2 {
  width: 23px;
  height: 21px;
}

.personsV2 {
  width: 21px;
  height: 21px;
  padding-right: 2px;
}

.cabinsV2 {
  width: 21px;
  height: 21px;
  padding-right: 2px;
}

.capitanV2 {
  width: 23px;
  height: 21px;
}

.timeV2 {
  width: 23px;
  height: 21px;
}

.capV2 {
  width: 21px;
  height: 21px;
  padding-right: 2px;
}

.location {
  width: 12px;
  min-width: 12px;
  height: 16px;
  fill: @primary-color;
}

.rating {
  width: 15px;
  min-width: 15px;
  height: 15px;
  fill: #ffcd1a;
}

.check {
  width: 16px;
  height: 16px;
}

.lockOutline {
  width: 28px;
  height: 28px;
  fill: @primary-color;
}

.match {
  width: 24px;
  height: 24px;
}

@primary-color: #355CA7;