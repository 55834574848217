.title {
  display: flex;
  align-content: center;
  margin: 12px 0;
  font-size: 18px;
  font-weight: 700;
}

.payments {
  display: flex;
  font-size: 16px;
  line-height: 22px;
  margin: 8px 0;

  .icon {
    margin-right: 16px;
  }

  .firstPayment,
  .secondPayment {
    font-weight: 700;
  }

  .firstPayment {
    margin-right: 6px;
  }

  .secondPayment {
    margin-left: 6px;
    margin-right: 6px;
  }
}

@primary-color: #355CA7;