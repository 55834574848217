@import '../../../../assets/styles/variables';

.main {
  @heightButtonSubmitMobile: 58px;
  @marginBottomPhoneMobile: 16px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 40px 16px;
  background-color: #f4f4f4;

  .text {
    width: 100%;
    padding-bottom: 32px;

    .title {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 4px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .form {
    width: 100%;

    .inputPhone {
      margin-bottom: @marginBottomPhoneMobile;
      padding-left: 30px;
      border-radius: 12px;
      background: #ffffff;
    }

    .buttonSubmit {
      height: 58px;
    }
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    max-width: 510px;
    width: 100%;
    border-radius: 12px;
    background: #ffffff;
    font-size: 16px;
    margin-bottom: @heightButtonSubmitMobile + @marginBottomPhoneMobile;

    .icon {
      margin-right: 8px;
    }

    &.loading .icon {
      color: @primary-color;
    }

    &.success .icon {
      color: @primary-color;
    }

    &.error .icon {
      color: #a60000;
    }
  }
}

.miniThemeInputPhone {
  margin-left: 32px;
  top: 6px;
}

@media screen and (min-width: @screen-sm-min) {
  .main {
    padding: 60px 20px;
    border-radius: 16px;

    .title,
    .description {
      text-align: center;
    }

    .form {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      max-width: 510px;
      height: 80px;
      padding: 0;
      border-radius: 40px;
      background: #ffffff;

      .inputPhone {
        background: unset;
        margin-bottom: unset;
      }

      .buttonSubmit {
        margin: 11px;
        max-width: 160px;
      }
    }

    .message {
      height: 80px;
      border-radius: 40px;
      margin-bottom: 0;
    }
  }
}

@primary-color: #355CA7;