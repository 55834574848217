@import '../../../../assets/styles/variables';

@heightField: 64px;
@fontSize: 16px;
@paddingLeft: 16px;
@paddingTop: (@heightField / 2 - @fontSize);
@radiusMobile: 15px;

.form {
  display: flex;
  flex-flow: column wrap;

  :global {
    .ant-select-selector,
    .ant-picker-input,
    .ant-input,
    .ant-input-affix-wrapper {
      height: @heightField !important;
    }

    .ant-input-affix-wrapper {
      .ant-input {
        margin-top: 2px;
        height: (@heightField - 4px) !important;
        min-height: (@heightField - 4px) !important;
      }
    }

    .ant-select-selector {
      padding-left: @paddingLeft !important;
      border-radius: @radiusMobile !important;
    }

    .ant-select-selection-placeholder {
      padding-top: @paddingTop !important;
      font-size: @fontSize !important;
    }

    .ant-select-selection-search {
      top: @paddingTop !important;
      left: @paddingLeft !important;
    }

    .ant-input,
    .ant-input-affix-wrapper {
      border-radius: @radiusMobile !important;
      margin-bottom: 16px;
    }
  }
}

.locationInput,
.dateInput,
.daysInput {
  width: 100%;
  margin-bottom: 16px;
  border-radius: @radiusMobile;
}

.daysInput {
  :global {
    .ant-select-selection-item {
      top: @paddingTop !important;
      font-size: @fontSize;
    }
  }
}

.buttonSubmit {
  width: 100%;
  max-width: 100%;
  height: @heightField;
  font-size: @fontSize;
  font-weight: 600;
}

@media screen and (min-width: @screen-md-min) {
  .form {
    justify-content: center;
    height: 80px;
    padding: 0 16px;
    max-width: 100%;
    box-shadow: 0 3px 24px 0 rgba(201, 200, 200, 0.5);
    border-radius: 40px !important;

    .locationInput {
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }

    .daysInput,
    .dateInput,
    .buttonSubmit {
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }

    .locationInput,
    .dateInput,
    .daysInput {
      margin-bottom: 0;
      border-radius: 0;
    }

    .buttonSubmit {
      margin: 0;
      min-height: 58px;
    }

    :global {
      .ant-select-selector {
        border-radius: unset !important;
        background-color: unset !important;
      }

      .ant-select .ant-select-selector,
      .ant-input,
      .ant-input-affix-wrapper,
      .ant-picker {
        border: none !important;
        box-shadow: none !important;
      }

      .ant-select-selector,
      .ant-input,
      .ant-input-affix-wrapper,
      .ant-picker {
        position: relative;
        background-color: #ffffff;
      }

      .ant-input-affix-wrapper {
        .ant-input {
          margin-top: 2px;
          height: 30px !important;
          min-height: 30px !important;
        }
      }
    }
  }
}

@primary-color: #355CA7;