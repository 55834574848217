.notOffers {
  display: flex;
  flex-flow: column wrap;
  row-gap: 32px;

  .title {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    text-align: center;
  }

  .text {
    text-align: center;
  }
}

@primary-color: #355CA7;