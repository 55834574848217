@import './../../assets/styles/variables';

.wrapperFilterAndResult {
  display: flex;
}

.wrapperFilter {
  flex: 0 0 auto;
  min-width: 310px;
  margin-right: 8px;
}

.wrapperResult {
  flex: 1 1 auto;
  margin-left: 8px;
}

.numberFound {
  margin-bottom: 0.5rem !important;
  color: rgba(0, 0, 0, 0.45);
}

.skeletonNumberFound {
  height: 22px;
  width: 120px;
}

.wrapperBoatList {
  display: flex;
  grid-gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;

  :global {
    .ant-pagination-item,
    .ant-pagination-item-link {
      border-radius: 16px;
    }
  }
}

@media screen and (max-width: 1660px) {
  .container {
    max-width: 1200px;
  }
}

@media screen and (max-width: @screen-md-max) {
  .wrapperFilterAndResult {
    flex-wrap: wrap;
  }

  .wrapperFilter {
    width: 100%;
    margin: 0 0 16px 0;
  }

  .wrapperResult {
    margin: 16px 0 0 0;
  }
}

@media screen and (min-width: @screen-xl-min) {
  .container {
    max-width: 1546px;
  }
}

@media screen and (max-width: 360px) {
  .wrapperFilter {
    min-width: 290px;
  }

  .wrapperBoatList {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }
}

@primary-color: #355CA7;