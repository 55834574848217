.main {
  display: flex;
  align-content: center;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .icon {
    margin: 2px 10px 0 2px;
  }
}

.skeleton {
  max-width: 360px;
  height: 22px;
  margin-bottom: 15px;
}

@primary-color: #355CA7;