@import '../../../assets/styles/variables';

.layout {
  display: flex;

  .form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .field {
      padding-right: 16px;

      .input {
        :global {
          .ant-form-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@primary-color: #355CA7;