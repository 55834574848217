.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  color: #757575;

  .label {
    width: 33.33%;
  }

  .value {
    width: 66.66%;
  }

  .value,
  a {
    color: #000000;
  }
}

@primary-color: #355CA7;