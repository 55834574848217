.main {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

.skeleton {
  height: 32px;
  margin-bottom: 15px;
}

@primary-color: #355CA7;