@import '../../../../assets/styles/variables';

.swiper {
  width: 100%;
  padding: 0 @container-padding !important;

  .slide {
    display: flex;
    align-items: flex-start;
    width: 280px !important;
    height: 401px !important;
    cursor: pointer;
  }
}

@primary-color: #355CA7;