.parameter {
  margin: 18px 0;
}

.label {
  margin-bottom: 6px;
  color: #a5a3a3;
}

.value {
  font-size: 16px;
}

@primary-color: #355CA7;