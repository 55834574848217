@import './../../../assets/styles/variables';

.option {
  display: flex;
  flex-direction: row;

  :global {
    .react-tel-input {
      min-width: 25px;
      min-height: 20px;
      width: 25px;
      height: 20px;
      margin: 0 4px 0 0;
    }
  }
}

.flag {
  display: block;
  min-width: 25px;
  min-height: 20px;
  width: 25px;
  height: 20px;
}

.marker {
  margin: 3px 11px 0 6px;
  fill: @primary-color;
}

@primary-color: #355CA7;