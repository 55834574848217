.main {
  height: 70px;
  padding: 14px 12px 0 30px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  &.themeLeft {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.themeRight {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  :global {
    .ant-select .ant-select-selector {
      border-color: #ffffff !important;
    }
    .ant-select-selection-item {
      font-size: 16px;
    }
  }
}

.select {
  :global {
    .ant-select-selector {
      margin-left: -11px;
      margin-top: -3px;
    }
  }
}

.label {
  font-size: 12px;
  color: #828282;
}

@primary-color: #355CA7;