.wrapper {
  border-radius: 15px;
  box-shadow: 0 0 3px rgba(100, 100, 100, 0.3), 1px 1px 2px rgba(100, 100, 100, 0.1);
}

.wrapperCategory {
  width: 100%;

  .category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px;
  }
}

.wrapperCondition {
  width: 100%;

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .condition {
    margin-top: 20px;
  }
}

.categoryLabel {
  margin-left: 16px;
  cursor: pointer;

  .icon {
    display: block;
    width: 85px;
    height: 38px;
  }
}

.categoryIcon {
  width: 85px;
  min-height: 38px;
}

.divider {
  margin: 10px 0;
}

.locations {
  :global {
    .ant-select-selector {
      border-radius: 32px !important;
      height: 50px !important;
    }

    .ant-select-selection-search {
      top: 10px !important;
      left: 22px !important;
    }

    .ant-select-selection-placeholder {
      padding: 10px !important;
    }
  }
}

.skeletonLocation {
  width: 100%;
  height: 50px;
  border-radius: 32px;
  margin-bottom: 24px;
}

.skeletonCategory {
  width: 100%;
  height: 52px;
}

.filters {
  padding: 12px 16px 0 12px;
}

@primary-color: #355CA7;