@import './../../../../assets/styles/variables';

@border-color: #e5e5e5;
@border-radius: 12px;

.photo {
  height: 191px;

  img {
    height: 191px;
    object-fit: cover;
    width: 100%;
    border-top-left-radius: @border-radius;
    border-top-right-radius: @border-radius;
  }
}

.main {
  padding: 8px 18px 18px 18px;
  border-top: 1px solid @border-color;

  .title {
    font-weight: 700;
    font-size: 22px;
  }

  .rating {
    display: flex;
    align-content: center;
    margin: 8px 0 16px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    .icon {
      height: 22px;
      margin-left: 8px;
    }
  }
}

@media screen and (min-width: @screen-md-min) {
  .photo {
    height: 250px;

    img {
      height: 250px;
    }
  }
}

@media screen and (min-width: @screen-md-min) AND (max-width: @screen-md-max) {
  .photo {
    height: 250px;

    img {
      height: 250px;
    }
  }
}

@primary-color: #355CA7;