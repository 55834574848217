@import './../../../../../assets/styles/variables';

@border: 1px solid #d0d0d0;
@borderRadius: 2px;
@widthSelectorFlag: 92px;
@widthSelectorFlagThemeMini: 36px;
@widthSpin: 36px;

.skeletonInput {
  padding-left: 124px !important;
}

.preview {
  display: flex;

  .selectorFlag {
    display: flex;
    align-items: center;
    width: @widthSelectorFlag + 1px;
    padding-left: 30px;
    border-bottom: @border;
    border-left: @border;
    border-top: @border;
    border-top-left-radius: @borderRadius;
    border-bottom-left-radius: @borderRadius;
    cursor: pointer;

    .icon {
      margin-left: 1px;
      width: 22px;
      height: 16px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAQBAMAAADgw5IVAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAMUExURQAAl/8AAKmy1////7D4FC0AAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAzSURBVBjTY4AARkEgYGACAwibQQEEmI2BAIs4snqIGkxxAuYg2WuMAAyCCIDCxqpGUBAAZ1MN5bX3P8cAAAAASUVORK5CYII=');
    }

    .arrow {
      margin-left: 6px;
      font-size: 7px;
    }
  }

  .input {
    width: calc(100% - @widthSelectorFlag);
    transition: none;

    &.withSpin {
      width: calc(100% - (@widthSelectorFlag + @widthSpin));
      border-right: 0;
    }
  }

  .spin {
    display: flex;
    align-items: center;
    width: @widthSpin;
    border-bottom: @border;
    border-right: @border;
    border-top: @border;
    border-top-right-radius: @borderRadius;
    border-bottom-right-radius: @borderRadius;
    font-size: 24px;
    color: @primary-color;
  }
}

.preview.themeMini {
  .selectorFlag {
    width: unset;
    padding-left: 0;
    border: 0;
  }

  .input {
    width: calc(100% - @widthSelectorFlagThemeMini);
    height: 56px;
    padding: 27px 12px 14px 25px;
    border: 0;
    border-radius: 12px;

    &.withSpin {
      width: calc(100% - (@widthSelectorFlagThemeMini + @widthSpin));
    }
  }

  .spin {
    border: 0;
  }
}

@media screen and (min-width: @screen-sm-min) {
  .preview.themeMini {
    .input {
      width: calc(100% - @widthSelectorFlagThemeMini);
      border: 0;
    }

    &.withSpin {
      width: calc(100% - (@widthSelectorFlagThemeMini + @widthSpin));
    }
  }
}

@primary-color: #355CA7;