@import './../../../../../../assets/styles/variables';

.total {
  display: flex;
  font-size: 16px;
  color: #6b6b6b;
  margin: 10px 0 32px 0;

  .rating {
    margin-left: 3px;
    margin-right: 2px;
  }

  .label {
    margin-right: 10px;
  }
}

@media screen and (max-width: @screen-lg-min) {
  .total {
    flex-wrap: wrap;

    .rating,
    .label,
    .customers {
      margin-top: 8px;
      width: 100%;
    }
  }
}

@primary-color: #355CA7;