@import './../../../../assets/styles/variables';

.payment {
  padding: 17px 20px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.promoCode {
  margin-bottom: 8px;
  .description {
    margin-bottom: 4px;
  }

  .discount,
  .code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .value {
      font-weight: bold;
    }
  }
}

.withoutDiscount {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin: 16px 0;

  .label {
    font-size: 16px;
    font-weight: 600;
  }

  .sub {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #8a8a8a;
  }

  .value {
    font-size: 16px;
    font-weight: 600;
  }
}

.itemPayment,
.firstPayment {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}

.secondPayment {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  .sub {
    line-height: 14px;
    color: #8a8a8a;
  }

  .value {
    color: #ff494b;
  }
}

@media screen and (min-width: @screen-lg-min) {
  .payment {
    padding: 24px 28px;
  }
}

@primary-color: #355CA7;