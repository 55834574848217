@import '../../../../assets/styles/variables';

.container {
  padding: 0 0 20px 20px;
  margin: 0 0 0 -@container-padding;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  padding: 0 0 40px 0;
}

.categories {
  width: 100%;
  padding: 0 0 24px 0 !important;

  .slide,
  .category {
    width: 280px;
    height: 250px;
  }

  .category {
    display: block;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 3px 24px rgba(219, 219, 219, 0.5);

    .photo {
      //display: inline-block;
      //position: relative;
      width: 280px;
      border-radius: 15px 15px 0 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-radius: 15px 15px 0 0;
    }

    .name {
      padding: 20px 20px 30px 20px;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      text-align: left;
      color: #000000;
    }
  }

  :global {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

@primary-color: #355CA7;