@import './../../../../assets/styles/variables';

.main {
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  padding: 32px 24px;
}

.name {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 32px;
}

.price {
  margin-bottom: -2px;

  .value {
    font-size: 52px;
    font-weight: 700;
    line-height: 63px;
    margin-right: 12px;
  }

  .time {
    font-size: 18px;
    font-weight: 400;
    line-height: 46px;
  }
}

.billed {
  margin-bottom: 19px;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  color: #828282;
}

.buttonSelect {
  width: 100%;
  min-height: 58px;
  margin-bottom: 20px;
}

.options {
  list-style: none;
  padding-left: 0;
  margin-bottom: -1px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;

  .option {
    display: flex;
    align-items: center;
    height: 40px;

    .icon {
      margin-right: 10px;

      &.included {
        color: #40b838;
      }

      &.notIncluded {
        color: #ff0000;
      }
    }
  }
}

.modal {
  .footer {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: @screen-lg-min) {
  .main {
    padding: 46px 32px;
  }
}

@primary-color: #355CA7;