@import './../../assets/styles/variables';

// TODO 2023 требует рефакторинга, удаление устаревшего

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  width: 100%;
  height: 62px;

  a {
    display: block;
    max-width: 133px;
    height: 54px;
  }
}

.buttonListYourBoat {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
}

.profile {
  min-width: 76px;
}

.linkCA {
  color: #000000;
}

@media screen and (max-width: 575px) {
  .buttonListYourBoat {
    flex: 1;
    min-width: 0;

    :global {
      .ant-btn {
        display: block;
        width: 100%;
        max-width: 180px;
        padding: 0 12px;
      }

      .ant-btn > span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (max-width: 374px) {
  .buttonListYourBoat {
    min-width: 0;

    :global {
      .ant-btn {
        padding: 0 6px;
      }

      .ant-btn > span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 359px) {
  .buttonListYourBoat {
    :global {
      .ant-btn > span {
        font-size: 11px;
      }
    }
  }
}

@media screen and (min-width: @screen-lg-min) {
  .logo {
    width: 133px;
  }

  .linkBuyBoats {
    display: block;
  }

  .buttonListYourBoat {
    display: block;
    justify-content: center;
    flex: 0 0 auto;
  }

  .linkProvidedForBoatList {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
  }
}

.languageSelector {
  display: none;
}

@media screen and (min-width: @screen-lg-min) {
  .languageSelector {
    display: flex;
  }
}

@primary-color: #355CA7;