.main {
  display: flex;
  flex-flow: row;

  .item {
    margin-right: 8px;
    height: 32px;

    .img {
      height: 32px;
    }
  }
}

@primary-color: #355CA7;