@import '../../../../assets/styles/variables';

.main {
  .header {
    display: none;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    padding-bottom: 24px;

    .item {
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      color: #000000;
      width: 100%;
      flex: 0 0 100%;
      padding: 24px;
      border: 1px solid #f0f0f0;
      border-radius: 10px;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        border-color: transparent;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
          0 5px 12px 4px rgba(0, 0, 0, 0.09);
      }

      .field {
        display: flex;
        column-gap: 8px;
        width: 100%;
        color: rgba(0, 0, 0, 0.85);
      }

      .id {
        .label,
        .value {
          color: #a5a3a3;
        }
      }

      .offers {
        color: @primary-color;
      }

      .count {
        margin: 1px 0 0 4px;
        min-width: 30px;
        height: 20px;
      }
    }
  }
}

@media screen and (min-width: @screen-lg-min) {
  .main {
    .header {
      display: flex;
      padding: 24px;
      color: #a5a3a3;

      .dateRange {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%;
      }
    }
    .list {
      .item {
        display: flex;
        justify-content: space-between;

        .field {
          width: unset;
        }

        .label {
          display: none;
        }

        .dateRange {
          flex: 0 0 29.16666667%;
          max-width: 29.16666667%;
        }
      }
    }

    .header,
    .list {
      .id {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%;
      }
      .createdAt {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
      }
      .address {
        flex: 0 0 29.16666667%;
        max-width: 29.16666667%;
      }

      .offers {
        flex: 0 0 12.5%;
        max-width: 12.5%;
      }
    }
  }
}

@primary-color: #355CA7;