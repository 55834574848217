@import '../../assets/styles/variables';

.layout {
  display: flex;
  flex-wrap: wrap;
}

:global {
  .ant-form-item-extra {
    color: #ff4d4f;
  }
}

.wrapperInput {
  margin-bottom: 0;

  :global {
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input:hover,
    .ant-input-affix-wrapper:hover {
      border-radius: 4px;
      border-color: @primary-color !important;
    }

    .form-control,
    .flag-dropdown {
      border: 1px solid @primary-color !important;
    }
  }
}

.wrapperPhone {
  :global {
    @flagButtonWidth: 92px;
    .react-tel-input {
      font-family: @font-family;
      height: 70px;

      .flag-dropdown {
        background: unset;
        border: 1px solid #d0d0d0;

        .selected-flag {
          width: @flagButtonWidth;
          padding-left: 30px;
        }
      }

      .form-control {
        min-height: 70px;
        padding: 37px 12px 14px @flagButtonWidth + 30px;
        border: 1px solid #d0d0d0;
        border-radius: 4px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

.description {
  margin: 24px 0 24px 0;
}

.info {
  padding: 40px 0;
  background: @primary-color;
  text-align: center;
  color: #ffffff;

  .title,
  .subTitle {
    width: 100%;
    padding: 0 16px;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
  }

  .subTitle {
    margin-bottom: 20px;
  }

  .description {
    margin: 0 0 24px 0;
    padding: 0 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;

    a {
      color: #ffffff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .steps {
    padding-bottom: 16px;
    max-height: 200px;

    :global {
      --swiper-pagination-color: #f4f4f4;
      --swiper-pagination-bullet-inactive-color: #fff;
      --swiper-pagination-bullet-size: 6px;
    }

    .step {
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      align-items: flex-start;

      .icon {
        width: 100%;
        margin-bottom: 8px;
        max-height: 57px;

        svg {
          width: 56px;
          max-height: 57px;
          fill: none;
        }
      }

      .title {
        width: 100%;
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
      }

      .description {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        margin: 0 -10px;
        padding: 0 10px;
        min-height: 95px;
      }
    }
  }
}

.form {
  margin-top: 30px;
}

@media screen and (min-width: @screen-md-min) {
  .layout {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    justify-content: center;
    max-width: @screen-sm-max;
    margin: auto;
    padding: 0 @container-padding;

    .info {
      padding: 50px 24px 0 40px;
      max-width: 600px;
      flex-basis: 600px;
      text-align: left;
      border-radius: 12px;

      .title,
      .subTitle {
        font-size: 52px;
        font-weight: 700;
        line-height: 63px;
      }

      .subTitle {
        margin-bottom: 30px;
      }

      .description {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 58px;
      }

      .steps {
        .step {
          display: flex;
          flex-flow: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          text-align: left;
          margin-bottom: 42px;

          .icon {
            width: 56px !important;
            margin-right: 30px;
          }

          .text {
            width: auto;

            .title {
              font-size: 24px;
              font-weight: 600;
              line-height: 29px;
              text-align: left;
            }

            .description {
              min-height: unset;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
            }
          }
        }
      }
    }

    .form {
      flex-basis: 514px;
      width: 514px;
      margin: 0 !important;
    }
  }

  .layoutCA {
    justify-content: space-between;
  }
}

@media screen and (min-width: @screen-lg-min) {
  .layout {
    max-width: @screen-md-max;
  }
}

@media screen and (min-width: @screen-xl-min) {
  .layout {
    max-width: @screen-lg-max;
  }
}

@primary-color: #355CA7;